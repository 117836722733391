<template>
    <div id="app" class="app">
        <header class="bg-light mb-4">
            <b-container>
                <b-row>
                    <b-navbar
                        class="w-100 d-flex justify-content-between align-items-center"
                    >
                        <b-navbar-brand>
                            <img
                                src="@/assets/servizi.digital.png"
                                alt=""
                                srcset=""
                            />
                        </b-navbar-brand>
                        <small class="app-name">Password Generator</small>
                    </b-navbar>
                </b-row>
            </b-container>
        </header>

        <b-container>
            <b-row>
                <b-col
                    class="password--item"
                    v-for="(item, index) in 3"
                    :key="index"
                >
                    <Passwords :key="generate" />
                </b-col>
            </b-row>

            <b-row class="md-layout mt-4">
                <b-col></b-col>
                <b-col>
                    <b-button
                        block
                        squared
                        variant="primary"
                        @click="generate++"
                    >
                        <b-icon icon="arrow-repeat"></b-icon>
                        Genera
                    </b-button>
                </b-col>
                <b-col></b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "App",
    components: {
        Passwords: () => import("./components/Passwords.vue"),
    },
    data() {
        return {
            generate: 0,
        };
    },
};
</script>

<style>
.navbar-brand.title {
    color: var(--gray);
}

.text-center {
    text-align: center;
}

.app-name {
    color: #aaa;
}
</style>
