import Vue from 'vue'

import { BootstrapVue, BootstrapVueIcons, CardPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(CardPlugin)

import Clipboard from 'v-clipboard'

Vue.use(Clipboard)

import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
